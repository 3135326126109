import { ErrorSanitizerService } from '@core/services/error-sanitizer.service';
import { CurrentCarrierService } from '@core/services/current-carrier.service';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of, from } from 'rxjs';
import { catchError, mergeMap, take, map, filter } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromActions from '@core/store/actions';
import { getProduct } from '../product/product.selectors';
import { setCurrentCarrier } from './current-carrier.action';
import { CurrentCarrierEntity } from './current-carrier.reducer';
import { CurrentCarrierUtils } from '@shared/utils/current-carrier.utils';

@Injectable()
export class CurrentCarrierEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private currentCarrierService: CurrentCarrierService,
    private errorSanitizerService: ErrorSanitizerService
  ) {}

  saveCurrentCarrier$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.CurrentCarrierActions.updateCurrentCarrier),
      mergeMap((action) =>
        this.store
          .select(getProduct(action.payload.productType || 'PersonalAuto'))
          .pipe(
            take(1),
            map((product) => ({
              action,
              product,
            }))
          )
      ),
      mergeMap(({ action, product }) => {
        const updateCurrentCarrierRequest =
          this.currentCarrierService.buildUpdateCurrentCarrierRequest(
            action.payload,
            product
          );
        return this.currentCarrierService
          .updateCurrentCarrier(updateCurrentCarrierRequest)
          .pipe(
            mergeMap((response) => {
              return from([
                fromActions.CurrentCarrierActions.updateCurrentCarrierSuccess({
                  payload: {
                    ...response,
                    productType: action.payload.productType,
                  },
                  correlationId: action.correlationId,
                }),
              ]);
            }),
            catchError((error) => {
              return of(
                fromActions.CurrentCarrierActions.updateCurrentCarrierFail({
                  error: this.errorSanitizerService.sanitizeError(
                    error,
                    product?.type
                  ),
                  correlationId: action.correlationId,
                })
              );
            })
          );
      })
    )
  );

  storeCurrentCarrierFromInitiateAutoResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.QuoteActions.initiateNewBusinessSuccess),
      map((action) => action.payload),
      filter((response) => response.productType === 'PersonalAuto'),
      filter((response) => !!response.currentCarrier?.currentCarrierName),
      map((response) =>
        setCurrentCarrier({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          payload: {
            ...response.currentCarrier!,
            productType: response.productType,
          },
          hit: CurrentCarrierUtils.responseIsComplete(response.currentCarrier),
        })
      )
    )
  );

  storeCurrentCarrierFromRetrieveAutoResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.RetrieveActions.retrieveQuoteSuccess),
      map((action) => action.payload),
      filter((response) => response.productType === 'PersonalAuto'),
      filter(
        (response) => !!response.response?.currentCarrier?.currentCarrierName
      ),
      map((response) =>
        setCurrentCarrier({
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          payload: {
            ...response.response?.currentCarrier!,
            productType: response.productType,
          },
          hit: CurrentCarrierUtils.responseIsComplete(
            response.response?.currentCarrier
          ),
        })
      )
    )
  );
}
