import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Nullable } from '@shared/utils/type.utils';
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Subject } from 'rxjs';
import { PcUrlService } from '@shared/services/pc-url.service';
import { LogService } from '@core/services/log.service';
import { ProductsService } from '@core/services/products.service';
import { ProductType } from '@core/models/api/dsm-types';
import { Router } from '@angular/router';
import { NavigationService } from '@core/services/navigation.service';
import {
  ALL_ELIGIBILITY_FORM_KEYS,
  ALL_PREQUALIFICATION_KEYS,
  ALL_RISK_ITEM_KEYS,
  EligibilityFormModel,
  EligibilityInlineMessage,
  EligibilityPrequalificationAnswers,
  EligibilityRiskItems,
} from '../store/eligibility-form.model';
import { filterOutNull } from '@shared/rxjs/filter-out-null.operator';
import { EligibilityFormService } from '../store/eligibility-form.service';
import { PrequalificationVerbiageModel } from '../prequalification-verbiage/prequalification-verbiage.reducer';
import { ErrorModel } from '@entities/error/error.model';
import { LayoutService } from '@core/services/layout.service';
import { ViewportType } from '@entities/layout/layout.entity';
import { FormHelper } from '@core/helper/form-helper';

type FlatModel = {
  [k in
    | keyof EligibilityPrequalificationAnswers
    | keyof Omit<EligibilityRiskItems, 'dogs'>]: string;
};

@Component({
  selector: 'nwx-eligibility-form',
  templateUrl: './eligibility-form.component.html',
  styleUrls: ['./eligibility-form.component.scss'],
})
export class EligibilityFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() model: Nullable<EligibilityFormModel>;
  @Input() readonly = false;
  @Input() highlightAcknowledgementRequired = false;
  @Input() productType = '';
  @Input() submitted = false;
  @Input() error: ErrorModel | null = null;
  @Input() showRiskItems!: boolean;
  @Output() valueChange = new EventEmitter<EligibilityFormModel>();
  @Output() statusChange = new EventEmitter<string>();
  @Output() dismissModal = new EventEmitter<void>();
  @Output() formReady = new EventEmitter<FormGroup>();

  showAcknowledgementHighlight = false;
  quoteId = '';
  horizontalRadio = false;

  form = new FormGroup<{ [key: string]: AbstractControl<string> }>({
    acknowledgement: new FormControl('', {
      nonNullable: true,
      validators: [(control) => this.validateAcknowledgement(control)],
    }),
  });

  private unsubscribe$ = new Subject<void>();
  private prequalificationVerbiage: PrequalificationVerbiageModel[] = [];

  constructor(
    private changeDetector: ChangeDetectorRef,
    private pcUrlService: PcUrlService,
    private logService: LogService,
    private window: Window,
    private productsService: ProductsService,
    private router: Router,
    private navigationService: NavigationService,
    private eligibilityFormService: EligibilityFormService,
    private layoutService: LayoutService
  ) {
    this.form.valueChanges
      .pipe(
        takeUntil(this.unsubscribe$),
        distinctUntilChanged((a, b) => this.formValuesEquivalent(a, b)),
        map((value) => this.eligibilityViewModelFromFormValue(value)),
        filterOutNull()
      )
      .subscribe((value) => {
        this.valueChange.emit(value);
      });
    this.form.statusChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status) => {
        this.statusChange.emit(status);
      });
    this.arrangeToDisableAcknowledgementWhenIncomplete();
    this.form
      .get('acknowledgement')
      ?.valueChanges.pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
      .subscribe((value) => {
        this.chooseShowAcknowledgementHighlight();
      });
    this.eligibilityFormService
      .getPrequalificationVerbiage()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((verbiage) => {
        this.prequalificationVerbiage = verbiage;
      });
    this.layoutService
      .getViewport()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((viewport) => {
        const displayHorizontal: ViewportType[] = [
          'mini',
          'medium',
          'large',
          'xlarge',
          'xxlarge',
        ];
        this.horizontalRadio = displayHorizontal.includes(viewport);
      });
  }

  ngOnInit(): void {
    // not sure why setting a true default on the input doesn't work in some cases
    if (this.showRiskItems === undefined) {
      this.showRiskItems = true;
    }
    this.setAcknowledgementField(this.showRiskItems);
    this.formReady.emit(this.form);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model) {
      this.rebuildForm();
    }
    if (changes.highlightAcknowledgementRequired) {
      this.chooseShowAcknowledgementHighlight();
    }
    if (changes.productType) {
      this.productsService
        .getQuoteIdForProduct(this.productType as ProductType)
        .pipe(take(1))
        .subscribe((quoteId) => {
          this.quoteId = quoteId;
          this.changeDetector.markForCheck();
        });
    }
  }

  setAcknowledgementField(isEnabled: boolean): void {
    FormHelper.enableOrDisable(
      'acknowledgement',
      isEnabled,
      [(control) => this.validateAcknowledgement(control)],
      this.form
    );
  }

  getEligibilityQuestionCodes(): string[] {
    if (this.model?.prequalificationAnswers) {
      const keys = Object.keys(this.model.prequalificationAnswers);
      const known = ALL_PREQUALIFICATION_KEYS.filter((k) => keys.includes(k));
      const unknown = keys.filter(
        (k) => !known.includes(k as keyof EligibilityPrequalificationAnswers)
      );
      return [...known, ...unknown];
    } else {
      return [];
    }
  }

  shouldShowQuestion(key: string): boolean {
    switch (key) {
      // Most riskItems, we should display if present in the model.
      // The decision was made earlier, by EligibilityFormService.
      case 'registeredHistoric':
      case 'nonResidentialToResidential':
      case 'deededOwner':
      case 'isShortSaleAuctForeclose':
      case 'trampoline':
      case 'dangerousOrExoticAnimal':
      case 'animalThatCausedInjury':
      case 'haveDogs':
      case 'incidentalFarm':
      case 'currentConstruction':
      case 'currentRemodelingOrRehabiliation':
      case 'swimmingPool': {
        return !!this.model?.riskItems?.hasOwnProperty(key);
      }

      // "dogs" never, they are handled special.
      case 'dogs':
        return false;

      // Trampoline details all conditional on "trampoline".
      case 'distanceToNonOwnedBuildingTrampoline':
        return this.model?.riskItems?.trampoline === 'true';
      case 'trampolineFence':
        return this.model?.riskItems?.trampoline === 'true';
      case 'trampolineTieDowns':
        return this.model?.riskItems?.trampoline === 'true';
      case 'trampolineNet':
        return this.model?.riskItems?.trampoline === 'true';

      // Swimming pool details are complicated...
      case 'typeOfSwimmingPool':
        return this.model?.riskItems?.swimmingPool === 'true';
      case 'distanceToNonOwnedBuildingPool':
        return this.model?.riskItems?.swimmingPool === 'true';
      case 'swimmingLessonsProvided':
        return this.model?.riskItems?.swimmingPool === 'true';
      case 'poolLadder':
        return this.model?.riskItems?.swimmingPool === 'true';
      case 'removableLadder':
        return (
          this.model?.riskItems?.swimmingPool === 'true' &&
          this.model?.riskItems?.poolLadder === 'true'
        );
      case 'divingBoard':
        return this.model?.riskItems?.swimmingPool === 'true';
      case 'waterDepthUnderDivingBoard':
        return (
          this.model?.riskItems?.swimmingPool === 'true' &&
          this.model?.riskItems?.divingBoard === 'true'
        );
      case 'poolSlide':
        return this.model?.riskItems?.swimmingPool === 'true';
      case 'waterDepthUnderSlide':
        return (
          this.model?.riskItems?.swimmingPool === 'true' &&
          this.model?.riskItems?.poolSlide === 'true'
        );
      case 'poolFence':
        return this.model?.riskItems?.swimmingPool === 'true';
      case 'selfLatchingGate':
        return this.model?.riskItems?.poolFence === 'true';
    }
    // Default case: If it exists in any of the model objects, yes.
    return (
      this.model?.prequalificationAnswers?.hasOwnProperty(key) ||
      this.model?.riskItems?.hasOwnProperty(key) ||
      false
    );
  }

  /** Empty string if this question should not be displayed, otherwise the verbiage to show.
   * (those two facts are combined; to reduce the calls coming in from the template).
   */
  getQuestionTextIfVisible(key: string): string {
    if (!this.shouldShowQuestion(key)) {
      return '';
    }
    const pqv = this.prequalificationVerbiage.find(
      (p) => p.questionCode === key
    )?.questionText;
    if (pqv) {
      return pqv;
    }
    switch (key) {
      case 'registeredHistoric':
        return 'Registered Historic Home';
      case 'nonResidentialToResidential':
        return 'Has the Dwelling been converted from non-residential to residential?';
      case 'deededOwner':
        return 'Are you the deeded owner of the property?';
      case 'isShortSaleAuctForeclose':
        return 'Was the property purchased via Public Auction, Short Sale or Foreclosure?';
      case 'trampoline':
        return 'Is there a trampoline on the premises?';
      case 'distanceToNonOwnedBuildingTrampoline':
        return 'Distance from trampoline to nearest non-owned building?';
      case 'trampolineFence':
        return 'Is the trampoline fenced?';
      case 'trampolineTieDowns':
        return 'Does the trampoline have tie-downs?';
      case 'trampolineNet':
        return 'Is the trampoline netted?';
      case 'dangerousOrExoticAnimal':
        return 'Any dangerous or exotic animals owned?';
      case 'animalThatCausedInjury':
        return 'Do you currently own an animal that has caused injury?';
      case 'haveDogs':
        return 'Any dogs on premises?';
      case 'dogs':
        return '';
      case 'incidentalFarm':
        return 'Do you operate Incidental Farming on the premises?';
      case 'currentConstruction':
        return 'Is the home currently in the course of construction?';
      case 'currentRemodelingOrRehabiliation':
        return 'Any current extensive remodeling or rehabilitation?';
      case 'swimmingPool':
        return 'Is there a swimming pool on premises?';
      case 'typeOfSwimmingPool':
        return 'Type of swimming pool?';
      case 'distanceToNonOwnedBuildingPool':
        return 'Distance from pool to nearest non-owned building?';
      case 'swimmingLessonsProvided':
        return 'Are swimming lessons provided at pool location?';
      case 'poolLadder':
        return 'Does the pool have a ladder?';
      case 'removableLadder':
        return 'Is the pool ladder removable?';
      case 'divingBoard':
        return 'Is there a diving board?';
      case 'waterDepthUnderDivingBoard':
        return 'Water depth under diving board?';
      case 'poolSlide':
        return 'Does the pool have a slide?';
      case 'waterDepthUnderSlide':
        return 'Water depth under slide?';
      case 'poolFence':
        return 'Is the pool fenced?';
      case 'selfLatchingGate':
        return 'Does the fence have a self-latching gate?';
    }
    return key;
  }

  getPicklistDisplayString(questionCode: string, key: string): string {
    switch (key) {
      case 'Greater1000ft':
        return 'Greater than 1000 ft';
      case 'LessThanEq1000ft':
        return 'Less or equal to 1000 ft';
      case 'aboveGround':
        return 'Above ground';
      case 'inground':
        return 'Inground pool';

      // Dog breeds:
      case 'AMiixOfAnyOfTheseBreeds':
        return 'Mixture of any above';
      case 'BullTerriers':
        return 'American Pit Bull Terrier';
      case 'Chows':
        return 'Chow-Chow';
      case 'DobermanPinschers':
        return 'Dobermans';
      case 'Other':
        return 'Other';
      case 'PitBulls':
        return 'Pit Bull Breeds';
      case 'PresaCanarios_Ext':
        return 'Presa Canarios';
      case 'Rottweilers':
        return 'Rottweilers';
      case 'StaffordshireBullTerriers':
        return 'Staffordshire Terrier';
      case 'WolfHybrids':
        return 'Wolf Hybrids';
      case 'NoneOfTheAbove_Ext':
        return 'None of the above';
    }
    return key;
  }

  shouldShowDogList(): boolean {
    return !!(this.model?.riskItems?.haveDogs === 'true');
  }

  onDogsChanged(riskItems: Partial<EligibilityRiskItems>): void {
    if (riskItems.dogs) {
      if (this.model) {
        this.model = {
          ...this.model,
          riskItems: {
            ...this.model.riskItems,
            dogs: [...riskItems.dogs],
          },
        };
        this.valueChange.next(this.model);
      }
    }
  }

  private flattenModel(): FlatModel {
    const tmp = {
      ...this.model?.prequalificationAnswers,
      ...this.model?.riskItems,
      acknowledgement: this.model?.acknowledgement ? 'true' : '',
    } as FlatModel;
    return tmp;
  }

  private rebuildForm(): void {
    if (!this.form.contains('acknowledgement')) {
      // shouldn't happen
      this.form.addControl(
        'acknowledgement',
        new FormControl(this.model?.acknowledgement ? 'true' : '', [
          Validators.required,
        ]),
        { emitEvent: false }
      );
      this.arrangeToDisableAcknowledgementWhenIncomplete();
    }
    // Even though the types don't say so, it is possible for this.model.prequalificationAnswers to have unknown keys.
    const keys = Array.from(
      new Set([
        ...ALL_ELIGIBILITY_FORM_KEYS,
        ...Object.keys(this.model?.prequalificationAnswers || {}),
      ])
    );
    for (const key of keys) {
      if (this.getQuestionTextIfVisible(key)) {
        const validators: ValidatorFn[] = [Validators.required];
        //TODO min and max validators
        const updateOn: 'change' | 'blur' = 'change';
        if (!this.form.contains(key)) {
          this.form.addControl(
            key,
            new FormControl('', {
              validators,
              updateOn,
            }),
            { emitEvent: false }
          );
        }
      } else {
        if (this.form.contains(key)) {
          this.form.removeControl(key, { emitEvent: false });
        }
      }
    }
    this.form.patchValue(this.flattenModel(), { emitEvent: true });
    this.form.updateValueAndValidity({ emitEvent: false });
    this.statusChange.next(this.form.status);
  }

  private arrangeToDisableAcknowledgementWhenIncomplete(): void {
    this.statusChange
      .pipe(
        takeUntil(this.unsubscribe$),
        map((status) => this.shouldEnableAcknowledgement(status))
      )
      .subscribe((enable) => {
        const control = this.form.get('acknowledgement');
        if (control && control.enabled !== enable) {
          if (enable) {
            this.form.get('acknowledgement')?.enable({ emitEvent: false });
          } else {
            this.form.get('acknowledgement')?.disable({ emitEvent: false });
          }
        }
      });
  }

  private shouldEnableAcknowledgement(formStatus: string): boolean {
    if (formStatus === 'VALID') {
      return true;
    }
    for (const key of Object.keys(this.form.controls)) {
      if (key === 'acknowledgement') {
        continue;
      }
      const control = this.form.controls[key];
      if (control && !control.valid) {
        return false;
      }
    }
    return true;
  }

  private formValuesEquivalent(a: any, b: any): boolean {
    const aKeys = Object.keys(a);
    const bKeys = Object.keys(b);
    if (aKeys.length !== bKeys.length) {
      return false;
    }
    for (const key of bKeys) {
      if (key === 'acknowledgement') {
        const aValue = !!a.acknowledgement;
        const bValue = !!b.acknowledgement;
        if (aValue !== bValue) {
          return false;
        }
      } else {
        if (b[key] !== a[key]) {
          return false;
        }
      }
    }
    return true;
  }

  private eligibilityViewModelFromFormValue(
    value: any
  ): EligibilityFormModel | null {
    if (!this.model) {
      return null;
    }
    const output: EligibilityFormModel = {
      ...this.model,
      acknowledgement:
        value.acknowledgement === 'true' || value.acknowledgement === true,
      prequalificationAnswers: {},
      riskItems: {},
    };
    for (const key of Array.from(
      new Set([
        ...ALL_PREQUALIFICATION_KEYS,
        ...Object.keys(this.model?.prequalificationAnswers || {}),
      ])
    ) as (keyof EligibilityPrequalificationAnswers)[]) {
      if (value.hasOwnProperty(key)) {
        output.prequalificationAnswers![key] = value[key];
      }
    }
    for (const key of ALL_RISK_ITEM_KEYS) {
      if (value.hasOwnProperty(key)) {
        output.riskItems![key] = value[key];
      }
    }
    if (this.model.riskItems?.dogs) {
      output.riskItems = {
        ...output.riskItems,
        dogs: this.model.riskItems.dogs.map((d) => ({ ...d })),
      };
    }
    return output;
  }

  private chooseShowAcknowledgementHighlight(): void {
    let newValue: boolean = this.showAcknowledgementHighlight;
    if (this.highlightAcknowledgementRequired) {
      newValue = !this.form.get('acknowledgement')?.value;
    }
    if (newValue !== this.showAcknowledgementHighlight) {
      this.showAcknowledgementHighlight = newValue;
      this.changeDetector.markForCheck();
    }
  }

  private validateAcknowledgement(
    control: AbstractControl
  ): ValidationErrors | null {
    if (!control.value) {
      return { fullVerbiageProvided: '' };
    }
    return null;
  }

  /* bolt-checkbox doesn't interact with <label> as you'd expect.
   * So we need this to make our label work with it.
   * Inlining the label content is not an option, because then the box flows with the first line of text instead of the block.
   * Faking a click on the bolt-checkbox also doesn't work.
   */
  redirectClickToCheckbox(): void {
    this.form.patchValue({
      acknowledgement: this.model?.acknowledgement ? '' : 'true',
    });
  }

  /** We may or may not be on the property page, in which case removing the property product would have disastrous consequences.
   * Check which page we're on. If it's property, navigate to PNI page and then remove the product.
   * Any other page, just remove it.
   */
  private carefullyRemoveTheCurrentPropertyProduct(): void {
    this.navigationService
      .getCurrentPage()
      .pipe(take(1))
      .subscribe((page) => {
        if (
          page?.associatedProducts?.includes(this.productType as ProductType)
        ) {
          this.router
            .navigateByUrl('/quote/pni')
            .then(() => {
              this.productsService.removeProductByType(
                this.productType as ProductType
              );
              this.dismissModal.next();
            })
            .catch((error) => {});
        } else {
          this.productsService.removeProductByType(
            this.productType as ProductType
          );
          this.dismissModal.next();
        }
      });
  }

  getQuestionMessage(key: string): EligibilityInlineMessage | null {
    return (
      this.model?.inlineMessages?.find((m) => m.questionCode === key) || null
    );
  }

  inlineMessageHtmlFromString(input: string): string {
    return input.replace(/</g, '&lt;').replace(/\n/g, '<br/>');
  }

  pivotToPc(questionCode: string): void {
    this.productsService
      .getQuoteIdForProduct(this.productType as ProductType)
      .pipe(
        take(1),
        switchMap((quoteId) =>
          this.pcUrlService.getPolicyCenterUrl(quoteId, 'Job').pipe(take(1))
        )
      )
      .subscribe((url) => {
        this.logService.logBusinessEvent('pivot-to-pc', {
          reason: `eligibility: ${questionCode}`,
        });
        this.window.location = url;
      });
  }

  removeProduct(): void {
    this.carefullyRemoveTheCurrentPropertyProduct();
  }
}
