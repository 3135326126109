import { Injectable } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { Nullable } from '@shared/utils/type.utils';
import { Store } from '@ngrx/store';
import { Observable, of, take } from 'rxjs';
import { FORM_VALIDATION_VERBIAGE } from '../constants/form-validation-verbiage';
import { PageIdentifier } from '../constants/pages';
import * as errorActions from '../store/entities/error/error.action';
import { ErrorModel } from '../store/entities/error/error.model';
import {
  getTaskNotificationsForPage,
  NotificationModel,
} from '../store/entities/task/selectors/task.selector';
import { setSideNavErrorIcon } from '../store/entities/error/error.action';
import { FormName } from '@core/models/api/nwx-types';
import { ErrorSelectors } from '@core/store/selectors';
import { hasMsbRetrievalError } from '@entities/error/error.selector';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessageService {
  constructor(private store: Store) {}

  addError(error: ErrorModel): void {
    this.store
      .select(ErrorSelectors.getErrors)
      .pipe(take(1))
      .subscribe((errors) => {
        if (errors.find((e) => this.errorModelsEquivalent(e, error))) {
        } else {
          this.store.dispatch(errorActions.addError({ payload: error }));
        }
      });
  }

  private errorModelsEquivalent(a: ErrorModel, b: ErrorModel): boolean {
    return (
      (a.developerMessage || '') === (b.developerMessage || '') &&
      (a.displayMessage || '') === (b.displayMessage || '') &&
      (a.productType || '') === (b.productType || '') &&
      (a.entityId || '') === (b.entityId || '')
    );
  }

  deleteErrorMessage(error: number): void {
    this.store.dispatch(errorActions.removeError({ id: error }));
  }

  getDefaultFormValidationErrorMessage(formName: string): string {
    return `${formName} is invalid`;
  }

  requiredErrorKeys = [
    'required',
    'picklistRequired',
    'emailIncomplete',
    'phoneNumberRequired',
    'consentRequired',
    'vinOrYmmRequired',
    'socialSecurityNumberRequired',
  ];

  validationErrorsAreDueToIncomplete(
    errors: Nullable<ValidationErrors>
  ): boolean {
    const nonRequiredKeys = Object.keys(errors || {}).filter((key) => {
      if (this.requiredErrorKeys.includes(key)) {
        return false;
      }
      if (key === 'arrayTooShort' && errors?.[key] === 1) {
        return false;
      }
      return true;
    });
    return nonRequiredKeys.length === 0;
  }

  errIsRequired(err: string): boolean {
    return this.requiredErrorKeys.includes(err);
  }

  getErrorMessageFromValidationErrors(
    errors: Nullable<ValidationErrors>,
    name: FormName
  ): string {
    const allRequiredErrs = Object.keys(errors || {}).every((err) =>
      this.errIsRequired(err)
    );

    // only report required errors if it's the only type of validation error
    if (allRequiredErrs) {
      const specificRequiredErrs = Object.keys(errors || {}).filter(
        (err) => this.errIsRequired(err) && err !== 'required'
      );
      const errName = specificRequiredErrs.length
        ? specificRequiredErrs[0]
        : 'required';
      const formValidationMessage = FORM_VALIDATION_VERBIAGE[errName];
      if (formValidationMessage) {
        return this.applyTemplate(formValidationMessage, errors?.[errName]);
      }
    } else {
      const nonRequiredErrs = Object.keys(errors || {}).filter(
        (err) => !this.errIsRequired(err)
      );
      const err = nonRequiredErrs.length > 0 ? nonRequiredErrs[0] : '';
      const template = FORM_VALIDATION_VERBIAGE[err];
      if (template) {
        return this.applyTemplate(template, errors?.[err]);
      }
    }
    return this.getDefaultFormValidationErrorMessage(name);
  }

  getVerbiageForErrorModel(error: ErrorModel): Observable<string> {
    // TODO
    return of(error.displayMessage || 'Error');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private applyTemplate(template: string, content: any): string {
    let output = '';
    let inputPosition = 0;
    const re = /{([^}]*)}/g;
    while (true) {
      const match = re.exec(template);
      if (!match) {
        break;
      }
      output += template.substring(inputPosition, match.index - inputPosition);
      inputPosition = match.index + match[0].length;
      if (match[1]) {
        output += content[match[1]] !== undefined ? content[match[1]] : '';
      } else {
        output += content;
      }
    }
    output += template.substring(inputPosition);
    return output;
  }

  shouldIgnoreErrorActionType(actionName: string): boolean {
    if (actionName === setSideNavErrorIcon.type) return true;
    return false;
  }

  getPageNotificationMessage(
    pageName: PageIdentifier
  ): Observable<Nullable<NotificationModel[]>> {
    return this.store.select(getTaskNotificationsForPage(pageName));
  }

  hasMsbRetrievalError(): Observable<boolean> {
    return this.store.select(hasMsbRetrievalError);
  }

  clearValidationErrorsForForm(formName: string): void {
    this.store.dispatch(
      errorActions.clearValidationErrorsForForm({ formName })
    );
  }
}
