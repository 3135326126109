<div class="hub-outer-container-top">
  <div class="bolt-container-fluid">
    <div class="bolt-row">
      <div class="bolt-col-12">
        <section>
          <nwx-notification-bar position="top"></nwx-notification-bar>
        </section>
        <section>
          <nwx-price-summary-container
            [maxSizeMedium]="maxSizeMedium$ | async"
            (showPdf)="getQuoteLetterPdf()"
          ></nwx-price-summary-container>
        </section>

        <section
          *ngIf="
            (selectedUnsupportedProducts$ | async) &&
            (maxSizeMedium$ | async) === false
          "
        >
          <nwx-unsupported-products-container>
          </nwx-unsupported-products-container>
        </section>
        <section *ngIf="hasExternalUnderlyPolicies$ | async">
          <bolt-notification
            type="warning"
            *ngIf="hasExternalUnderlyPolicies$ | async"
          >
            <div class="bolt-row">
              <div class="bolt-col-10">
                External underlying policies require underwriting approval.
                Please continue to PolicyCenter to issue.
              </div>
              <div class="bolt-col-2">
                <bolt-button type="primary" (click)="continueToPC()"
                  >Continue to PolicyCenter</bolt-button
                >
              </div>
            </div>
          </bolt-notification>
        </section>
        <section
          *ngIf="hasSecondaryMatchFlag$ | async"
          class="notification"
          id="uwReportsReview"
        >
          <nwx-notification-bubble type="info">
            <div class="bolt-row">
              <div class="bolt-col-10">
                Please review all accident information on the reports and
                underwriting tab.
              </div>
              <div class="bolt-col-2">
                <bolt-button (click)="dismissNotification('uwReportsReview')"
                  >Dismiss</bolt-button
                >
              </div>
            </div>
          </nwx-notification-bubble>
        </section>
        <nwx-notification-bar position="bottom"></nwx-notification-bar>
        <section class="mvr-notification" *ngIf="showMVRDisclaimer">
          <nwx-notification-bubble type="info" size="sm" paddingsize="sm">{{
            mvrDisclaimerText
          }}</nwx-notification-bubble>
        </section>
        <section>
          <nwx-persona-display
            [personaReport]="personaReport$ | async"
          ></nwx-persona-display>
        </section>
        <section *ngIf="(maxSizeMedium$ | async) === false">
          <nwx-up-next-container></nwx-up-next-container>
        </section>
      </div>
    </div>
  </div>
</div>

<div class="hub-outer-container-bottom">
  <div class="bolt-container-fluid">
    <section
      *ngIf="(selectedUnsupportedProducts$ | async) && (maxSizeMedium$ | async)"
    >
      <div class="bolt-row">
        <div class="bolt-col">
          <nwx-unsupported-products-container>
          </nwx-unsupported-products-container>
        </div>
      </div>
    </section>
    <section *ngIf="maxSizeMedium$ | async">
      <div class="bolt-row">
        <div class="bolt-col">
          <nwx-up-next-container></nwx-up-next-container>
        </div>
      </div>
    </section>
    <div class="review-section">
      <div class="bolt-row">
        <div class="bolt-col-12">
          <section>
            <nwx-review-policies-container
              (scrollToTasks)="scrollToTasks()"
            ></nwx-review-policies-container>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="sticky-footer bolt-background-white button-bar-width-limiter">
  <div
    *ngIf="showMVRDisclaimer"
    class="mvr-notification-bottom bolt-space-inner-wide-lg"
  >
    <nwx-notification-bubble
      *ngIf="showMVRDisclaimer"
      type="info"
      size="sm"
      paddingsize="sm"
      >{{ mvrDisclaimerText }}</nwx-notification-bubble
    >
  </div>
  <bolt-button-bar *ngIf="forceRebuild()" class="bolt-background-white">
    <bolt-button
      [disabled]="!quoteLetter"
      slot="cancel"
      [attr.id]="'hpc-quote-letter-pdf'"
      (click)="getQuoteLetterPdf()"
      iconright="download"
      size="lg"
      width="full-sm"
      >Quote PDF</bolt-button
    >

    <bolt-button
      slot="back"
      iconleft="external-link"
      *ngIf="selectedUnsupportedProducts$ | async"
      (click)="launchUnsupportedModal()"
      [attr.id]="'hpc-unsupported-modal'"
      size="lg"
      width="full-sm"
    >
      Quote PolicyCenter Products
    </bolt-button>

    <ng-container *ngIf="nextStep$ | async; let nextStep">
      <bolt-button
        slot="forward"
        iconleft="refresh"
        *ngIf="nextStep.buttonType === 'recalculate'; else regularForwardButton"
        (click)="onSubmit()"
        [attr.id]="'hpc-reclaculate-quote'"
        size="lg"
        width="full-sm"
        [disabled]="nextStep.buttonDisabled || null"
      >
        {{ nextStep.buttonLabel }}
      </bolt-button>

      <ng-template #regularForwardButton>
        <bolt-button
          slot="forward"
          type="primary"
          (click)="onSubmit()"
          [iconright]="forwardButtonIcon"
          attr.id="hpc-{{ nextStep.buttonLabel }}"
          size="lg"
          width="full-sm"
          [disabled]="nextStep.buttonDisabled || null"
          *ngIf="nextStep.buttonReady !== false; else buttonShapedSpinner"
        >
          {{ nextStep.buttonLabel }}
        </bolt-button>
      </ng-template>

      <ng-template #buttonShapedSpinner>
        <div slot="forward" class="loading-outer">
          <span class="loading">Loading</span>
          <bolt-waiting-indicator minimal> </bolt-waiting-indicator>
        </div>
      </ng-template>
    </ng-container>
  </bolt-button-bar>
</div>
