import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ProductType } from "@core/models/api/dsm-types";
import { Subject, takeUntil } from "rxjs";
import { EligibilityDog, EligibilityRiskItems } from "../store/eligibility-form.model";
import { EligibilityFormService } from "../store/eligibility-form.service";

@Component({
  selector: 'nwx-eligibility-dog-list',
  templateUrl: './eligibility-dog-list.component.html',
  styleUrls: ['./eligibility-dog-list.component.scss'],
})
export class EligibilityDogListComponent
  implements OnChanges, OnInit, OnDestroy
{
  @Input() riskItems!: Partial<EligibilityRiskItems>;
  @Input() quoteId = '';
  @Input() submitted = false;
  @Output() valueChange = new EventEmitter<Partial<EligibilityRiskItems>>();
  @Output() removeProduct = new EventEmitter<void>();

  readonly dogBreeds = [
    { value: 'BullTerriers', displayName: 'American Pit Bull Terrier' },
    { value: 'Chows', displayName: 'Chow-Chow' },
    { value: 'DobermanPinschers', displayName: 'Dobermans' },
    { value: 'PitBulls', displayName: 'Pit Bull Breeds' },
    { value: 'PresaCanarios_Ext', displayName: 'Presa Canarios' },
    { value: 'Rottweilers', displayName: 'Rottweilers' },
    {
      value: 'StaffordshireBullTerriers',
      displayName: 'Staffordshire Terrier',
    },
    { value: 'WolfHybrids', displayName: 'Wolf Hybrids' },
    { value: 'AMiixOfAnyOfTheseBreeds', displayName: 'Mixture of any above' },
    { value: 'NoneOfTheAbove_Ext', displayName: 'None of the above' },
  ];

  form = new FormGroup({
    dogs: new FormArray<any>([]),
  });
  private unsubscribe$ = new Subject<void>();
  showInlineMessage = false;

  constructor(private eligibilityFormService: EligibilityFormService) {}

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.valueChange.next(this.eligibilityRiskItemsFromFormValue(value));
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.riskItems) {
      this.rebuildForm();
      this.showInlineMessage = !!this.riskItems?.dogs?.find(
        (d) => d.biteHistory === 'true'
      );
    }
  }

  private eligibilityRiskItemsFromFormValue(
    formValue: any
  ): Partial<EligibilityRiskItems> {
    const output: Partial<EligibilityRiskItems> = { dogs: [] };
    for (const dog of formValue.dogs || []) {
      output.dogs!.push({ ...dog });
    }
    return output;
  }

  private rebuildForm(): void {
    const dogCount = this.riskItems?.dogs?.length || 0;
    const formArray = this.form.get('dogs') as FormArray;
    while (formArray.length > dogCount) {
      formArray.removeAt(formArray.length - 1, { emitEvent: false });
    }
    while (formArray.length < dogCount) {
      formArray.push(
        new FormGroup({
          dogBreed: new FormControl('', [Validators.required]),
          biteHistory: new FormControl('', [Validators.required]),
          canineGoodCitizen: new FormControl('', [Validators.required]),
        }),
        { emitEvent: false }
      );
    }
    for (let i = 0; i < dogCount; i++) {
      const dog = this.riskItems.dogs![i];
      const formGroup = formArray.at(i);
      formGroup.patchValue(dog, { emitEvent: false });
    }
  }

  getDogControls(): FormGroup[] {
    return (
      ((this.form.get('dogs') as FormArray)?.controls as FormGroup[]) || []
    );
  }

  onAdd(): void {
    if (!this.quoteId) {
      return;
    }
    this.eligibilityFormService.addDog(this.quoteId);
  }

  onRemove(index: number): void {
    if (!this.quoteId) {
      return;
    }
    this.eligibilityFormService.removeDog(this.quoteId, index);
  }
}
