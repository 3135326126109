import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import * as fromStore from '@core/store/reducers';
import { DistributedDocumentsAdapter } from '@core/adapters/distributed-documents.adapter';
import { DistributedDocumentsResponse } from '../models/api/response/distributed-documents-response.model';
import { Store } from '@ngrx/store';
import { PersonaSelectors, QuoteLetterSelectors } from '../store/selectors';
import { take } from 'rxjs/operators';
import { Base64Utils } from '@shared/utils/base64.utils';
import { NavigationService } from './navigation.service';
import { HttpClient } from '@angular/common/http';
import {
  PersonaReport,
  PersonaNameToSupplementPdfPath,
  PersonaName,
} from '@entities/persona/persona.model';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root',
})
export class QuoteLetterService {
  constructor(
    private distributedDocumentsAdapter: DistributedDocumentsAdapter,
    private store: Store<fromStore.CoreState>,
    private navigationService: NavigationService,
    private base64Utils: Base64Utils,
    private httpClient: HttpClient,
    private logService: LogService
  ) {}

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  documents(request: any): Observable<DistributedDocumentsResponse> {
    return this.distributedDocumentsAdapter.documents(request);
  }

  getQuoteLetter(): Observable<string> {
    return this.store.select(QuoteLetterSelectors.getQuoteLetter);
  }

  getPersonaReport(): Observable<PersonaReport> {
    return this.store.select(PersonaSelectors.getPersonaReport);
  }

  showQuoteLetterInNewTab(): void {
    combineLatest([this.getQuoteLetter(), this.getPersonaReport()])
      .pipe(take(1))
      .subscribe(([letter, personaReport]) =>
        this.showEncodedQuoteLetterInNewTab(letter, personaReport.name)
      );
  }

  showEncodedQuoteLetterInNewTab(
    base64EncodedPdf: string,
    personaName: PersonaName
  ): void {
    if (!base64EncodedPdf) {
      return;
    }
    this.getPersonaSupplementPdfBlob(personaName).subscribe(
      (supplementBlob) => {
        this.logService.logBusinessEvent('show-quote-pdf', {
          personaName,
          personaSupplement: !!supplementBlob.size,
        });
        this.base64Utils
          .decodeBase64ToBlobUrl(
            base64EncodedPdf,
            'application/pdf',
            supplementBlob
          )
          .then((url) => {
            this.navigationService.openExternalPage(url, '_blank');
          })
          .catch((err) => {
            // Opportunity here to report the error.
          });
      }
    );
  }

  getPersonaSupplementPdfBlob(personaName: PersonaName): Observable<Blob> {
    let supplementBlob: Blob = new Blob();
    const pdfPath = PersonaNameToSupplementPdfPath[personaName];
    if (!!pdfPath) {
      return this.httpClient
        .get(pdfPath, { responseType: 'blob' })
        .pipe(take(1));
    } else {
      return of(supplementBlob);
    }
  }
}
