import { DriverVehiclePrefillResponseDriver } from '@core/models/api/response/driver-vehicle-prefill-response.model';
import { MemberModel } from '@core/models/views/person.model';
import { DateUtils } from '../date.utils';
import { Nullable } from '../type.utils';

export class DriverBuilder {
  static buildDriverEntitiesFromPrefillResponse(
    prefill: DriverVehiclePrefillResponseDriver[]
  ): Nullable<MemberModel[]> {
    if (!prefill) {
      return null;
    }
    return prefill.map((driver) => {
      let dateOfBirth = driver.dateOfBirth || '';
      if (
        dateOfBirth.includes('*') &&
        +dateOfBirth.substring(0, 4) === new Date().getFullYear() - 25
      ) {
        dateOfBirth = '';
      } else {
        dateOfBirth = DateUtils.formatDsmDateToOld(dateOfBirth);
      }
      let gender = driver.genderCode?.toUpperCase() as string | null;
      if (gender !== 'M' && gender !== 'F') {
        gender = null;
      }
      return {
        prefillId: driver.prefillId,
        person: {
          firstName: driver.firstName,
          middleName: driver.middleName ? driver.middleName.slice(0, 1) : null,
          lastName: driver.lastName,
          dateOfBirth,
          ssn: driver.socialSecurityNumber,
          gender,
          maritalStatus: driver.maritalstatus,
        },
        licenseNumber: driver.driversLicenseNumber?.toString(),
        licenseState: driver.state,
        selected: false,
      } as MemberModel;
    });
  }
}
